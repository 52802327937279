import React from "react";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import GoogleMapReact from "google-map-react";
import Header from "../Common/Header";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/contact.jpg");

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const ContactUs = () => {
  const defaultProps = {
    center: {
      lat: 34.07328,
      lng: -118.25141,
    },
    zoom: 12,
  };
  const contSub = (e) => {
    e.preventDefault();
    alert("Submit Successfully!.Contact you soon...");
    e.target.reset();
  };
  return (
    <>
      <Header />
      <Helmet>
        <title>
          Contact Us | arK designspace - Get in Touch for Architecture & Design
          Services
        </title>
        <link rel="canonical" href="https://arkdesignspace.com/contact-us" />

        <meta
          name="description"
          content="Reach out to arK designspace for inquiries about architectural design, 3D modeling, rendering, and interior solutions. Contact us today to bring your vision to life."
        />

        <meta
          property="og:title"
          content="Contact Us | arK designspace - Get in Touch for Architecture & Design Services"
        />
        <meta
          property="og:description"
          content="Reach out to arK designspace for inquiries about architectural design, 3D modeling, rendering, and interior solutions. Contact us today to bring your vision to life."
        />

        <meta
          name="keywords"
          content="arK designspace contact, contact architecture firm, design services inquiry, 3D modeling contact, rendering services inquiry, interior design contact, architecture support, contact arK designspace"
        />

        <meta
          property="og:image"
          content="https://arkdesignspace.com/ark.jpg"
        />
        <meta property="og:image:type" content="image/png" />
      </Helmet>
      <div className="page-content">
        <Banner
          title="Contact Us"
          pagename="Contact us"
          description="Don't ignore the effort of a person who tries to keep in touch It's not all the time someone cares."
          bgimage={bnrimg}
        />
        {/* SECTION CONTENTG START */}
        <div className="section-full p-tb80 inner-page-padding">
          {/* LOCATION BLOCK*/}
          <div className="container">
            {/* GOOGLE MAP & CONTACT FORM */}
            <div className="section-content">
              {/* CONTACT FORM*/}
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <form
                    className="contact-form  bg-gray p-a30"
                    method="post"
                    onSubmit={contSub}
                  >
                    <div className="contact-one">
                      {/* TITLE START */}
                      <div className="section-head">
                        <div className="sx-separator-outer separator-left">
                          <div
                            className="sx-separator bg-white bg-moving bg-repeat-x"
                            style={{
                              backgroundImage:
                                "url(images/background/cross-line2.png)",
                            }}
                          >
                            <h3 className="sep-line-one">Get in Touch </h3>
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <div className="form-group">
                        <input
                          name="username"
                          type="text"
                          required
                          className="form-control"
                          placeholder="Name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          required
                          placeholder="Email"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="number"
                          type="text"
                          className="form-control"
                          required
                          placeholder="Phone No"
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          rows={4}
                          className="form-control "
                          required
                          placeholder="Message"
                          defaultValue={""}
                        />
                      </div>
                      <div className="text-right">
                        <button type="submit" className="site-button btn-half">
                          <span> submit</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div
                    className="contact-info block-shadow bg-white bg-center p-a40"
                    style={{
                      backgroundImage: "url(images/background/bg-map.png)",
                    }}
                  >
                    <div>
                      {/* TITLE START */}
                      <div className="section-head">
                        <div className="sx-separator-outer separator-left">
                          <div
                            className="sx-separator bg-white bg-moving bg-repeat-x"
                            style={{
                              backgroundImage:
                                "url(images/background/cross-line2.png)",
                            }}
                          >
                            <h3 className="sep-line-one">Info</h3>
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <div className="sx-icon-box-wraper left p-b30">
                        <div className="icon-xs">
                          <i className="fa fa-phone" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Phone number</h5>
                          <p className="mb-0">+91 91504 49980</p>
                          <p>+91 83449 99980</p>
                        </div>
                      </div>
                      <div className="sx-icon-box-wraper left p-b30">
                        <div className="icon-xs">
                          <i className="fa fa-envelope" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Email address</h5>
                          <p className="mb-0">karthik2007.arch4@gmail.com</p>
                          <p>info@arkdesignspace.com</p>
                        </div>
                      </div>
                      <div className="sx-icon-box-wraper left">
                        <div className="icon-xs">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Address info</h5>
                          <p className="mb-0">
                            <b>arK designspace, Karthik Selvaraj</b>
                          </p>
                          <p>
                            No-29A, 1st Floor Peons Colony, 4th_Street,
                            Kavundampalayam, Coimbatore - 641030.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gmap-outline">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0",
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
              lat={34.07328}
              lng={-118.25141}
              text={<i className="fa fa-map-marker" />}
            />
          </GoogleMapReact>
        </div>
        {/* SECTION CONTENT END */}
      </div>

      <Footer />
    </>
  );
};

export default ContactUs;
