import React from "react";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import Services7 from "./../Elements/Services7";
import Header from "../Common/Header";
import Testimonials2 from "../Elements/Testimonials2";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/service.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            Services | arK designspace - Comprehensive Architecture & Design
            Solutions
          </title>
          <link rel="canonical" href="https://arkdesignspace.com//services" />

          <meta
            name="description"
            content="Explore the wide range of services offered by arK designspace, including architectural design, 3D modeling, rendering, interior design, and project management. Tailored solutions to bring your vision to life."
          />

          <meta
            property="og:title"
            content="Services | arK designspace - Comprehensive Architecture & Design Solutions"
          />
          <meta
            property="og:description"
            content="Explore the wide range of services offered by arK designspace, including architectural design, 3D modeling, rendering, interior design, and project management. Tailored solutions to bring your vision to life."
          />

          <meta
            name="keywords"
            content="arK designspace services, architectural design, 3D modeling, rendering services, interior design, project management, creative design solutions, design consultancy, space planning, architecture firm"
          />

          <meta
            property="og:image"
            content="https://arkdesignspace.com/ark.jpg"
          />
          <meta property="og:image:type" content="image/png" />
        </Helmet>
        <div className="page-content">
          <Banner
            title="Our Service"
            pagename="Services"
            description="When I'm working on a problem, I never think about its beauty, I just think about how to solve the problem, but when I finish it, if the solution is not beautiful, I know it's wrong."
            bgimage={bnrimg}
          />

          <Services7 />
          <Testimonials2 separatoralignment="separator-center" />
        </div>
        <Footer />
      </>
    );
  }
}

export default Service1;
