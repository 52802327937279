import React, { useEffect } from "react";
// import Header4 from './../Common/Header4';
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import About2 from "./../Elements/About2";
import Achievements1 from "./../Elements/Achievements1";
import Team2 from "./../Elements/Team2";
import Testimonials2 from "./../Elements/Testimonials2";
import Header from "../Common/Header";
import WhatWeDo1 from "../Elements/WhatWeDo1";
import SimilarProjects from "../Elements/SimilarProjects";
import ClientsLogo1 from "../Elements/ClientsLogo1";
import { Helmet } from "react-helmet";
import Team3 from "../Elements/Team3";

var bnrimg = require("./../../images/banner/5.jpg");

const About1 = () => {
  useEffect(() => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
        loadScript("./assets/js/custom.js");
      });
    }
  });

  return (
    <>
      <Header />
      <Helmet>
        <title>
          About Us | arK designspace - Redefining Creativity and Innovation in
          Architecture & Design
        </title>
        <link rel="canonical" href="https://arkdesignspace.com/about" />

        <meta
          name="description"
          content="Explore arK designspace, a leading design firm specializing in architecture, 3D modeling, rendering, and creative solutions. Learn about our mission, expertise, and commitment to excellence in design."
        />

        <meta
          property="og:title"
          content="About Us | arK designspace - Redefining Creativity and Innovation in Architecture & Design"
        />
        <meta
          property="og:description"
          content="Explore arK designspace, a leading design firm specializing in architecture, 3D modeling, rendering, and creative solutions. Learn about our mission, expertise, and commitment to excellence in design."
        />

        <meta
          name="keywords"
          content="arK designspace, architecture firm, creative design solutions, 3D modeling, rendering services, innovative architecture, about arK designspace, architectural expertise"
        />

        <meta
          property="og:image"
          content="https://arkdesignspace.com/ark.jpg"
        />
        <meta property="og:image:type" content="image/png" />
      </Helmet>
      <div className="page-content">
        <Banner
          title="About Us"
          pagename="About"
          description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized."
          bgimage={bnrimg}
        />
        <About2 />
        <SimilarProjects alignment="separator-left" title="" />
        <WhatWeDo1 />

        <Achievements1 />
        <Team3 />
        <Testimonials2 separatoralignment="separator-center" />
        <ClientsLogo1 />
      </div>

      <Footer />
    </>
  );
};

export default About1;
