import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import Header from "../Common/Header";
import sanityClient from "../../client";
import { Helmet } from "react-helmet";

const filters = [
  { label: "Architectural", filter: ".Architectural" },
  { label: "Construction", filter: ".Construction" },
  { label: "Interior", filter: ".Interior" },
];

var bnrimg = require("./../../images/banner/project.jpg");
var bgimg1 = require("./../../images/background/cross-line.png");

const ProjectMasonary3 = () => {
  const [allPostsData, setAllPosts] = useState(null);
  useEffect(() => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }
    loadScript("./assets/js/custom.js");

    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        category,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);
  if (!allPostsData) {
    return <h3>Loading...</h3>;
  }

  return (
    <>
      <Header />
      <Helmet>
        <title>
          Projects | arK designspace - Inspiring Architecture & Creative Design
          Portfolio
        </title>
        <link rel="canonical" href="https://www.yourwebsite.com/projects" />

        <meta
          name="description"
          content="Discover the diverse portfolio of arK designspace. Explore innovative architectural projects, 3D models, rendering designs, and creative spaces crafted with precision and passion."
        />

        <meta
          property="og:title"
          content="Projects | arK designspace - Inspiring Architecture & Creative Design Portfolio"
        />
        <meta
          property="og:description"
          content="Discover the diverse portfolio of arK designspace. Explore innovative architectural projects, 3D models, rendering designs, and creative spaces crafted with precision and passion."
        />

        <meta
          name="keywords"
          content="arK designspace projects, architecture portfolio, creative designs, 3D rendering, innovative architecture, design solutions, completed projects, project gallery, architectural excellence"
        />

        <meta
          property="og:image"
          content="https://arkdesignspace.com/ark.jpg"
        />
        <meta property="og:image:type" content="image/png" />
      </Helmet>
      <div className="page-content">
        <Banner
          title="arK Projects"
          pagename="Projects"
          description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized."
          bgimage={bnrimg}
        />

        {/* SECTION CONTENT START */}
        <div className="section-full p-tb80 bg-white inner-page-padding">
          {/* GALLERY CONTENT START */}
          <div className="container">
            {/* Filter Nav START */}
            <div className="filter-wrap p-b30 text-center">
              <ul className="filter-navigation masonry-filter clearfix">
                <li className="active">
                  <NavLink
                    to={"#"}
                    className="btn from-top"
                    data-filter="*"
                    data-hover="All"
                  >
                    All
                  </NavLink>
                </li>
                {filters.map((item, index) => (
                  <li key={index}>
                    <NavLink
                      to={"#"}
                      className="btn from-top"
                      data-filter={item.filter}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            {/* Filter Nav END */}
            {/* GALLERY CONTENT START */}
            <ul className="masonry-outer mfp-gallery work-grid row clearfix list-unstyled">
              {allPostsData &&
                allPostsData.map((post, index) => (
                  <div
                    key={index}
                    className={`${post.category} masonry-item col-lg-4  col-md-4 col-sm-6 m-b30`}
                  >
                    <div className="project-mas hover-shadow">
                      <div className="image-effect-one">
                        <img src={post.mainImage.asset.url} alt="" />
                        <div className="figcaption">
                          <NavLink
                            className="mfp-link s"
                            to={post.mainImage.asset.url}
                            target="_blank"
                          >
                            <i className="fa fa-arrows-alt" />
                          </NavLink>
                        </div>
                      </div>
                      <div className="project-info p-a20 bg-gray">
                        <h4 className="sx-tilte m-tb0">
                          <NavLink to={`/project-detail/${post.slug.current}`}>
                            {post.title}
                          </NavLink>
                        </h4>
                        <p>{post.category}</p>
                        <NavLink to={"#"}>
                          <i className="link-plus bg-primary" />
                        </NavLink>
                        <button className="site-button-secondry btn-half mtb-10 ptb-10">
                          {" "}
                          <NavLink to={`/project-detail/${post.slug.current}`}>
                            <span> More Details</span>
                          </NavLink>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </ul>
            {/* GALLERY CONTENT END */}
          </div>
          {/* GALLERY CONTENT END */}
        </div>
        {/* SECTION CONTENT END  */}
      </div>

      <Footer />
    </>
  );
};

export default ProjectMasonary3;
