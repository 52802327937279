import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import Header from "../Common/Header";
import { format } from "date-fns";
import sanityClient from "../../client";
import { Helmet } from "react-helmet";
import Iframe from "react-iframe";

var bnrimg = require("./../../images/banner/blog.jpg");

const VideoPage = () => {
  const [allVideos, setAllVideos] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "video"]{
          link,  
          title,               
    }`
      )
      .then((data) => setAllVideos(data))
      .catch(console.error);
  }, []);

  if (!allVideos) {
    return <h3>Loading...</h3>;
  }
  return (
    <>
      <Header />
      <Helmet>
        <title>
          Youtube Videos | arK designspace - Insights on Architecture, Design
          Trends, and Innovations
        </title>
        <link rel="canonical" href="https://arkdesignspace.com/blogs" />

        <meta
          name="description"
          content="Stay updated with arK designspace's blog. Explore articles on architecture, design trends, 3D modeling, rendering tips, and creative innovations that inspire and inform."
        />

        <meta
          property="og:title"
          content="Youtube Videos | arK designspace - Insights on Architecture, Design Trends, and Innovations"
        />
        <meta
          property="og:description"
          content="Stay updated with arK designspace's blog. Explore articles on architecture, design trends, 3D modeling, rendering tips, and creative innovations that inspire and inform."
        />

        <meta
          name="keywords"
          content="arK designspace blog, architecture insights, design trends, 3D modeling tips, rendering techniques, creative innovations, design inspiration, architecture blog, design articles"
        />

        <meta
          property="og:image"
          content="https://arkdesignspace.com/ark.jpg"
        />
        <meta property="og:image:type" content="image/png" />
      </Helmet>
      <div className="page-content">
        <Banner
          title="arK Videos"
          pagename="Videos"
          description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized."
          bgimage={bnrimg}
        />
        {/* SECTION CONTENT START */}
        <div className="section-full p-tb80 inner-page-padding">
          {/* GALLERY CONTENT START */}
          <div className="container clearfix">
            <div className="  row">
              {allVideos &&
                allVideos.map((item, index) => (
                  <div
                    className="masonry-item  col-lg-6 col-md-6 col-sm-12 m-b-30"
                    key={index}
                  >
                    <Iframe
                      url={item.link.replace(/youtu.be/g, "youtube.com/embed")}
                      width="100%"
                      height="320px"
                      id=""
                      className=""
                      display="block"
                      position="relative"
                    />
                  </div>
                ))}
            </div>
          </div>
          {/* GALLERY CONTENT END */}
        </div>
        {/* SECTION CONTENT END  */}
      </div>

      <Footer />
    </>
  );
};

export default VideoPage;
