import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import Header from "../Common/Header";
import { format } from "date-fns";
import sanityClient from "../../client";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/blog.jpg");

const BlogMasonary = () => {
  const [allBlogsData, setAllBlogs] = useState(null);
  useEffect(() => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }
    loadScript("./assets/js/custom.js");

    sanityClient
      .fetch(
        `*[_type == "blogs"]{
          title,
          slug,
          publishedAt,      
          mainImage{
            asset->{
            _id,
            url 
          }
        }
    }`
      )
      .then((data) => setAllBlogs(data))
      .catch(console.error);
  }, []);

  if (!allBlogsData) {
    return <h3>Loading...</h3>;
  }
  return (
    <>
      <Header />
      <Helmet>
        <title>
          Blogs | arK designspace - Insights on Architecture, Design Trends, and
          Innovations
        </title>
        <link rel="canonical" href="https://arkdesignspace.com/blogs" />

        <meta
          name="description"
          content="Stay updated with arK designspace's blog. Explore articles on architecture, design trends, 3D modeling, rendering tips, and creative innovations that inspire and inform."
        />

        <meta
          property="og:title"
          content="Blogs | arK designspace - Insights on Architecture, Design Trends, and Innovations"
        />
        <meta
          property="og:description"
          content="Stay updated with arK designspace's blog. Explore articles on architecture, design trends, 3D modeling, rendering tips, and creative innovations that inspire and inform."
        />

        <meta
          name="keywords"
          content="arK designspace blog, architecture insights, design trends, 3D modeling tips, rendering techniques, creative innovations, design inspiration, architecture blog, design articles"
        />

        <meta
          property="og:image"
          content="https://arkdesignspace.com/ark.jpg"
        />
        <meta property="og:image:type" content="image/png" />
      </Helmet>
      <div className="page-content">
        <Banner
          title="arK Blogs"
          pagename="Blogs"
          description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized."
          bgimage={bnrimg}
        />
        {/* SECTION CONTENT START */}
        <div className="section-full p-tb80 inner-page-padding">
          {/* GALLERY CONTENT START */}
          <div className="container clearfix">
            <div className="masonry-outer mfp-gallery news-masonry  row">
              {allBlogsData.map((item, index) => (
                <div
                  className="masonry-item  col-lg-4 col-md-6 col-sm-12"
                  key={index}
                >
                  <div className="blog-post blog-grid date-style-2">
                    <div className="sx-post-media sx-img-effect img-reflection">
                      <NavLink to={`/blog-detail/${item.slug.current}`}>
                        <img src={item.mainImage.asset.url} alt="" />
                      </NavLink>
                    </div>
                    <div className="sx-post-info p-t30">
                      <div className="sx-post-meta ">
                        <ul>
                          <li className="post-date">
                            <strong>
                              {format(new Date(item.publishedAt), "dd")}
                            </strong>{" "}
                            <span>
                              {format(
                                new Date(item.publishedAt),
                                "MMMM"
                              ).substring(0, 3)}
                            </span>{" "}
                          </li>
                          <li className="post-author">
                            <NavLink to={`/blog-detail/${item.slug.current}`}>
                              By <span>arK</span>
                            </NavLink>{" "}
                          </li>
                          <li className="post-comment">
                            {" "}
                            <NavLink to={`/blog-detail/${item.slug.current}`}>
                              Blogs
                            </NavLink>{" "}
                          </li>
                        </ul>
                      </div>
                      <div className="sx-post-title ">
                        <h2 className="post-title">
                          <NavLink to={`/blog-detail/${item.slug.current}`}>
                            {item.title}
                          </NavLink>
                        </h2>
                      </div>
                      <div className="sx-post-readmore">
                        <NavLink
                          to={`/blog-detail/${item.slug.current}`}
                          title="READ MORE"
                          rel="bookmark"
                          className="site-button-link"
                        >
                          More Details
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* GALLERY CONTENT END */}
        </div>
        {/* SECTION CONTENT END  */}
      </div>

      <Footer />
    </>
  );
};

export default BlogMasonary;
