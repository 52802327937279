import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import Projects6 from "./../Elements/Projects6";
import Header from "../Common/Header";
import { format } from "date-fns";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";
import BlockContent from "@sanity/block-content-to-react";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/project.jpg");

const ProjectDetail1 = () => {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
        title,
        slug,
        category,
        client,
        location,
        budget,
        publishedAt,
        mainImage{
          asset->{
            _id,
            url
           }
         },
         imagesGallery[]{
          asset->{
            _id,
            url
           }
         },   
       body, 
      
     }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  });

  return (
    <>
      <Header />
      <Helmet>
        <title>
          {postData ? postData.title : ""} | arK designspace - Innovative
          Architecture & Design Showcase
        </title>
        <link
          rel="canonical"
          href={`https://arkdesignspace.com/project-detail/${
            postData ? postData.slug.current : ""
          }`}
        />

        <meta
          name="description"
          content={`Explore details of ${
            postData ? postData.title : ""
          } by arK designspace. Discover innovative architectural solutions, creative designs, and expert craftsmanship showcased in this project.`}
        />
        <meta
          property="og:title"
          content={`${
            postData ? postData.title : ""
          } |  arK designspace - Innovative Architecture & Design Showcase`}
        />

        <meta
          property="og:description"
          content={`Explore details of ${
            postData ? postData.title : ""
          } by arK designspace. Discover innovative architectural solutions, creative designs, and expert craftsmanship showcased in this project.`}
        />

        <meta
          name="keywords"
          content={`${
            postData ? postData.title : ""
          }, arK designspace projects, innovative architecture, creative design, 3D rendering, architectural excellence`}
        />

        <meta
          property="og:image"
          content="https://arkdesignspace.com/ark.jpg"
        />
        <meta property="og:image:type" content="image/png" />
      </Helmet>
      <div className="page-content">
        <Banner
          title="Project Details"
          pagename="Project Detail"
          description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized."
          bgimage={bnrimg}
        />
        {!postData ? (
          <></>
        ) : (
          <>
            <div className="section-full p-tb80 inner-page-padding stick_in_parent">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-7  sticky_column">
                    <div className="project-detail-containt stiCkky">
                      <div className="bg-white text-black ">
                        <h2>{postData.title}</h2>
                        <p>
                          <BlockContent
                            blocks={postData.body}
                            projectId="ajavin5j"
                            dataset="production"
                          />
                        </p>
                        <div className="product-block">
                          <ul>
                            <li>
                              <h4 className="m-b10">Date</h4>
                              <p>
                                {format(
                                  new Date(postData.publishedAt),
                                  " MMMM, dd, yyyy"
                                )}
                              </p>
                            </li>
                            <li>
                              <h4 className="m-b10">Client</h4>
                              <p>{postData.client}</p>
                            </li>
                            <li>
                              <h4 className="m-b10">Project type</h4>
                              <p>{postData.category}</p>
                            </li>
                            <li>
                              <h4 className="m-b10">Budget</h4>
                              <p>{postData.budget}</p>
                            </li>
                            <li>
                              <h4 className="m-b10">Location</h4>
                              <p>{postData.location}</p>
                            </li>
                          </ul>
                        </div>
                        <div className="m-b0">
                          <div className="sx-divider divider-1px  bg-black">
                            <i className="icon-dot c-square" />
                          </div>
                        </div>
                        <ul className="social-icons social-md social-square social-dark m-b0">
                          <li>
                            <FacebookShareButton
                              url={`https://arkdesignspace.com/project-detail/${postData.slug.current}`}
                            >
                              <a
                                href="javascript:void(0)"
                                target="_blank"
                                className="fa fa-facebook"
                              />
                            </FacebookShareButton>
                          </li>
                          <li>
                            <TwitterShareButton
                              url={`https://arkdesignspace.com/project-detail/${postData.slug.current}`}
                            >
                              <a
                                href="javascript:void(0)"
                                target="_blank"
                                className="fa fa-twitter"
                              />
                            </TwitterShareButton>
                          </li>
                          <li>
                            <WhatsappShareButton
                              url={`https://arkdesignspace.com/project-detail/${postData.slug.current}`}
                            >
                              <a
                                href="javascript:void(0)"
                                target="_blank"
                                className="fa fa-whatsapp"
                              />
                            </WhatsappShareButton>
                          </li>
                          <li>
                            <TelegramShareButton
                              url={`https://arkdesignspace.com/project-detail/${postData.slug.current}`}
                            >
                              <a
                                href="javascript:void(0)"
                                target="_blank"
                                className="fa fa-telegram"
                              />
                            </TelegramShareButton>
                          </li>
                          <li>
                            <EmailShareButton
                              url={`https://arkdesignspace.com/project-detail/${postData.slug.current}`}
                            >
                              <a
                                href="javascript:void(0)"
                                target="_blank"
                                className="fa fa-envelope-o"
                              />
                            </EmailShareButton>
                          </li>
                          <li>
                            <LinkedinShareButton
                              url={`https://arkdesignspace.com/project-detail/${postData.slug.current}`}
                            >
                              <a
                                href="javascript:void(0)"
                                target="_blank"
                                className="fa fa-linkedin"
                              />
                            </LinkedinShareButton>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 ">
                    <div className="project-detail-outer">
                      {!postData.imagesGallery ? (
                        <>
                          <div className="project-detail-pic m-b30 stiCkky">
                            <div className="sx-media">
                              <img src={postData.mainImage.asset.url} alt="" />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {postData.imagesGallery.map((imgGal, indexGal) => (
                            <div
                              className="project-detail-pic m-b30 stiCkky"
                              key={indexGal}
                            >
                              <div className="sx-media">
                                <img src={imgGal.asset.url} alt="" />
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* SECTION CONTENT START */}

        {/* SECTION CONTENT END  */}
        <Projects6 />
      </div>

      <Footer />
    </>
  );
};

export default ProjectDetail1;
