import sanityClient from "../../client";
import React, { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/background/cross-line2.png");

const Team3 = () => {
  const [allteam, setAllTeam] = useState(null);
  useEffect(() => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }
    loadScript("./assets/js/custom.js");
    sanityClient
      .fetch(
        `*[_type == "team"] | order(position asc) {
          name,
          des,
          position,
          image{
            asset->{
            _id,
            url
          }
        }
      }`
      )
      .then((data) => setAllTeam(data))
      .catch(console.error);
  }, []);
  if (!allteam) {
    return <h3>Loading...</h3>;
  }
  return (
    <>
      <div className="section-full p-t80 p-b50 bg-gray mobile-page-padding">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head">
            <div className="sx-separator-outer separator-center">
              <div
                className="sx-separator bg-white bg-moving bg-repeat-x"
                style={{ backgroundImage: "url(" + bgimg1 + ")" }}
              >
                <h3 className="sep-line-one">Our Experts</h3>
              </div>
            </div>
          </div>
          {/* TITLE END */}
          {/* IMAGE CAROUSEL START */}
          <div className="section-content">
            <div className="row justify-content-center">
              {allteam &&
                allteam.map((item, index) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 m-b30"
                    key={index}
                  >
                    <div className="our-team-3">
                      <div className="our-team-info ">
                        <img src={item.image.asset.url} alt="" />
                        <div className="our-team-content">
                          <h4 className="sx-team-name">
                            <a href="team-single.html">{item.name}</a>
                          </h4>
                          <span className="sx-team-position text-white">
                            {item.des}
                          </span>
                          <p>
                            <a href="/home" className="sideLogo">
                              ARK design space
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="hilite-title text-left p-l50 text-uppercase">
          <strong>Experts</strong>
        </div>
      </div>
    </>
  );
};

export default Team3;
