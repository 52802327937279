import React, { useEffect, useState } from "react";
import Navigation from "../Common/Navigation";
import { NavLink } from "react-router-dom";
import whatsappimg from "../../images/socialmedia/whatsapp.png";
import insta from "../../images/socialmedia/insta.png";
import facebook from "../../images/socialmedia/facebook.png";
import youtube from "../../images/socialmedia/youtube.png";

const bnr = require("./../../images/background/bg-map.png");
const logo = require("./../../images/logo-1.png");

const Header = () => {
  const [isQuoteActive, setIsQuoteActive] = useState(false);
  const EnActive = () => {
    setIsQuoteActive(!isQuoteActive);
  };
  // const handleQuoteToggle = () => {};
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const stickyheader = document.querySelector(".sticky-header ");

      if (offset >= 100) {
        stickyheader.classList.add("is-fixed");
        stickyheader.classList.add("color-fill");
      } else {
        stickyheader.classList.remove("is-fixed");
        stickyheader.classList.remove("color-fill");
      }
    };
    window.addEventListener("scroll", handleScroll);
  }, []);
  //form submit
  const enSub = (e) => {
    e.preventDefault();
    setIsQuoteActive(isQuoteActive);
    alert("Submit Successfully!.Contact you soon...");
    e.target.reset();
  };

  return (
    <>
      <header className="site-header header-style-1 nav-wide mobile-sider-drawer-menu">
        <div className="top-bar bg-gray">
          <div className="container">
            <div className="d-flex justify-content-end">
              <ul className="list-unstyled e-p-bx">
                <li>
                  <span>Mail us:</span> arkdesignspaces@gmail.com
                </li>
                <li>
                  <span>Call us:</span>+91 91504 49980
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar header-left-gray-block bg-white">
            <div className="container clearfix">
              <div className="logo-header">
                <div className="logo-header-inner logo-header-one">
                  <NavLink to={"/"}>
                    <img src={logo} alt="Inteshape" />
                  </NavLink>
                </div>
              </div>
              {/* NAV Toggle Button */}
              <button
                id="mobile-side-drawer"
                data-target=".header-nav"
                data-toggle="collapse"
                type="button"
                className="navbar-toggler "
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>
              {/* EXTRA NAV */}
              <div className="extra-nav">
                <div className="extra-cell">
                  <div className="contact-slide-show">
                    <NavLink
                      to={"#"}
                      onClick={EnActive}
                      className="get-in-touch-btn from-top"
                    >
                      Get in touchs
                    </NavLink>
                  </div>
                </div>
              </div>
              {/* EXTRA Nav */}
              {/* MAIN NAVIGATION */}
              <div className="header-nav nav-dark navbar-collapse collapse justify-content-start collapse">
                <Navigation />
              </div>
              {/* CONTACT */}
              <div
                className="contact-slide-hide"
                style={{
                  backgroundImage: "url(" + bnr + ")",
                  right: isQuoteActive ? "0px" : "100%",
                  transition: "0.7s",
                }}
              >
                <div className="contact-nav">
                  <NavLink
                    onClick={EnActive}
                    to={"#"}
                    className="contact_close"
                  >
                    ×
                  </NavLink>
                  <div className="contact-nav-form">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-12 ">
                        <div
                          className="contact-nav-field shadow-lg p-a30 bg-white"
                          style={{ backgroundImage: "url(" + bnr + ")" }}
                        >
                          <h4>Enquiry</h4>
                          <form
                            className=" form-transparent"
                            method="post"
                            onSubmit={enSub}
                          >
                            <div className="input input-animate">
                              <input
                                defaultValue=""
                                type="text"
                                name="username"
                                id="name"
                                placeholder="Name"
                                required
                              />
                              <span className="spin" />
                            </div>
                            <div className="input input-animate">
                              <input
                                type="email"
                                defaultValue=""
                                placeholder="Email"
                                name="email"
                                id="email"
                                required
                              />
                              <span className="spin" />
                            </div>
                            <div className="input input-animate">
                              <input
                                type="text"
                                name="phone"
                                placeholder="Phone No"
                                defaultValue=""
                                id="Phone"
                                required
                              />
                              <span className="spin" />
                            </div>
                            <div className="input input-animate">
                              <textarea
                                placeholder="Message"
                                name="message"
                                id="message"
                                required
                                defaultValue={""}
                              />
                              <span className="spin" />
                            </div>
                            <div className="text-left p-t10">
                              <button
                                type="submit"
                                className="site-button-secondry btn-half "
                              >
                                <span> Submit Now</span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className=" contact-nav-info">
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-phone" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Phone number</h6>
                              <p>+91 91504 49980 | +91 83449 99980</p>
                            </div>
                          </div>
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-envelope" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Email address</h6>
                              <p className="mb-0">arkdesignspaces@gmail.com</p>
                              <p>info@arkdesignspace.com</p>
                            </div>
                          </div>
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-map-marker" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Address info</h6>

                              <p>
                                No - 29A, 1st, Floor, 4th St, Peons Colony,
                                Koundampalayam, Coimbatore, Tamil Nadu 641030.
                              </p>
                            </div>
                          </div>
                          <div className="full-social-bg">
                            <ul>
                              <li>
                                <a
                                  href="https://www.facebook.com/profile.php?id=61550791378653&mibextid=ZbWKwL"
                                  target="_blank"
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.instagram.com/arkdesignspaces?igsh=MXJscnZjeWZpNzIwMQ%3D%3D"
                                  target="_blank"
                                >
                                  <i className="fa fa-instagram" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="float-sm">
        <div className="fl-fl float-fb">
          <img className="icn_img" src={insta} alt="instagram" />
          <a href="https://www.instagram.com/arkdesignspaces/" target="_blank">
            Instagram
          </a>
        </div>
        <div className="fl-fl float-tw">
          <img className="icn_img" src={facebook} alt="facebook" />
          <a
            href="https://www.facebook.com/profile.php?id=61550791378653"
            target="_blank"
          >
            Facebook
          </a>
        </div>
        <div className="fl-fl float-gp">
          <img className="icn_img" src={youtube} />
          <a href="https://www.youtube.com/@arKDesignSpace" target="_blank">
            Youtube
          </a>
        </div>

        <div className="fl-fl float-rs">
          <img className="icn_img" src={whatsappimg} />
          <a
            href="https://api.whatsapp.com/send?phone=+919003591413&amp;text=Hi, I would like to discuss further on the services offered by your company . Kindly Connect - Thanks."
            target="_blank"
          >
            Whatsapp
          </a>
        </div>
        {/* <div className="fl-fl float-ig">
          <img
            className="icn_img"
            src={whatsappimg}
          />
          <a href="#" target="_blank">
            Application
          </a>
        </div> */}
      </div>
    </>
  );
};

export default Header;
