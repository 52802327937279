import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Slider1 from "./../Elements/Slider1";
import About1 from "./../Elements/About1";
import WhatWeDo1 from "./../Elements/WhatWeDo1";
import Testimonials1 from "./../Elements/Testimonials1";
import Services1 from "./../Elements/Services1";
import Team2 from "./../Elements/Team2";
import Projects4 from "../Elements/Projects4";
// import Slider3 from "../Elements/Slider3";

class Home1 extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Slider1 />
          {/* <Slider3 /> */}
          <About1 />
          <WhatWeDo1 />
          <Testimonials1 />
          <Services1 />
          <Projects4 />
          {/* <ClientsLogo1 /> */}
          {/* <ClientsLogo2 /> */}
          {/* <ClientsLogo5 /> */}
          <Team2 />
        </div>
        <Footer />
      </>
    );
  }
}

export default Home1;
