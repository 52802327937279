import React from "react";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import Header from "../Common/Header";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/faq.jpg");

class Faq extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Helmet>
          <title>
            FAQs | arK designspace - Your Questions Answered About Architecture
            & Design Services
          </title>
          <link rel="canonical" href="https://arkdesignspace.com/faq" />

          <meta
            name="description"
            content="Find answers to frequently asked questions about arK designspace's architectural and design services, including pricing, project timelines, 3D modeling, and rendering solutions."
          />

          <meta
            property="og:title"
            content="FAQs | arK designspace - Your Questions Answered About Architecture & Design Services"
          />
          <meta
            property="og:description"
            content="Find answers to frequently asked questions about arK designspace's architectural and design services, including pricing, project timelines, 3D modeling, and rendering solutions."
          />

          <meta
            name="keywords"
            content="arK designspace FAQ, architecture questions, design services FAQs, 3D modeling FAQs, rendering solutions FAQ, project timelines FAQ, pricing questions, arK designspace help, frequently asked questions"
          />

          <meta
            property="og:image"
            content="https://arkdesignspace.com/ark.jpg"
          />
          <meta property="og:image:type" content="image/png" />
        </Helmet>
        <div className="page-content">
          <Banner
            title="Frequently Asked Questions"
            pagename="Faq"
            description="Question with boldness even the existence of God; because if there be one, he must more approve of the homage of reason, than that of blindfolded fear."
            bgimage={bnrimg}
          />
          {/* SECTION CONTENTG START */}
          <div className="section-full p-t80 p-b80 inner-page-padding">
            <div className="container">
              <div className="faq-1">
                {/* TITLE START */}
                <div className="section-head">
                  <div className="sx-separator-outer separator-left">
                    <div
                      className="sx-separator bg-white bg-moving bg-repeat-x"
                      style={{
                        backgroundImage:
                          "url(images/background/cross-line2.png)",
                      }}
                    >
                      <h3 className="sep-line-one">FAQ</h3>
                    </div>
                  </div>
                </div>
                {/* TITLE END */}
                <div className="row">
                  <div className="col-md-6 faq-list">
                    <div className="sx-accordion acc-bg-gray" id="accordion5">
                      <div className="panel sx-panel">
                        <div className="acod-head acc-actives">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseOne1"
                              data-parent="#accordion5"
                            >
                              What is the role of an architect in the design
                              process?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseOne1"
                          className="acod-body collapse show"
                        >
                          <div className="acod-content p-a15">
                            <p>
                              Architects are professionals who plan and create
                              functional and aesthetic designs for buildings and
                              spaces. They consider the client's requirements,
                              budget, and site conditions to develop plans and
                              oversee the construction process.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo2"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How do you ensure that the architectural design
                              aligns with the client's vision?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo2" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              We begin with a detailed consultation to
                              understand the client's needs and preferences.
                              Regular communication and feedback sessions are
                              held throughout the design process to ensure
                              alignment with the client's vision.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseThree3"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Can you handle both residential and commercial
                              architectural projects?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThree3" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Yes, we specialize in both residential and
                              commercial architectural designs. Our diverse
                              portfolio showcases our ability to create
                              innovative and functional spaces for various
                              purposes.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFour4"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              What construction services do you offer?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFour4" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              We provide comprehensive construction services,
                              including project management, site supervision,
                              quality control, and coordination with
                              subcontractors. Our goal is to ensure that the
                              construction process is efficient and meets
                              high-quality standards.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How do you handle project timelines and deadlines?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive5" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              We develop detailed project schedules during the
                              planning phase, taking into account all aspects of
                              the construction process. Regular progress
                              assessments are conducted to ensure adherence to
                              timelines.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive6"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              What is the importance of a construction contract?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive6" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              A construction contract outlines the terms,
                              conditions, and expectations of the project. It
                              helps in preventing disputes, establishes clear
                              roles and responsibilities, and provides a legal
                              framework for the construction process.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive15"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How do you handle unexpected challenges or changes
                              during construction?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive15" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Flexibility is key. We maintain open communication
                              with clients and promptly address any unforeseen
                              challenges. We work collaboratively to find
                              practical solutions that align with the project
                              goals.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive16"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How do you balance functionality and aesthetics in
                              interior design?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive16" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Our design process involves a thorough
                              understanding of the client's lifestyle and
                              functional requirements. We strive to create
                              aesthetically pleasing spaces that also fulfill
                              practical needs.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive17"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Can you recommend sustainable and eco-friendly
                              interior design practices?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive17" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Absolutely. We incorporate sustainable materials,
                              energy-efficient lighting, and environmentally
                              conscious practices in our interior designs to
                              promote a greener living or working environment.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* Accordian */}
                    <div className="sx-accordion acc-bg-gray" id="accordion5">
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive7"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              What is the process for an interior makeover
                              project?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseFive7"
                          className="acod-body collapse show"
                        >
                          <div className="acod-content p-a15">
                            <p>
                              Our interior makeover process typically involves
                              an initial consultation, conceptual design
                              development, client feedback, and the execution
                              phase. We work closely with clients to transform
                              their spaces according to their preferences.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo8"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Can you work with existing furniture and decor in
                              an interior makeover?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo8" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Absolutely. We understand the value of sentimental
                              or existing pieces. We integrate them into the new
                              design, ensuring a harmonious blend of old and new
                              elements.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo9"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How do you stay updated with the latest interior
                              design trends?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo9" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              We begin with a detailed consultation to
                              understand the client's needs and preferences.
                              Regular communication and feedback sessions are
                              held throughout the design process to ensure
                              alignment with the client's vision.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseThree10"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              What factors do you consider when designing a
                              sustainable building?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapseThree10"
                          className="acod-body collapse"
                        >
                          <div className="acod-content p-a15">
                            <p>
                              Our sustainable design approach involves
                              considerations such as energy efficiency, use of
                              eco-friendly materials, site orientation, and
                              incorporation of renewable energy sources to
                              minimize environmental impact.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFour11"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Can you assist with obtaining necessary permits
                              for construction projects?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFour11" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Yes, part of our services includes helping clients
                              navigate the permitting process. We ensure that
                              all required permits are obtained, and regulatory
                              requirements are met.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive12"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How do you address accessibility and universal
                              design in your architectural projects?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive12" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              We prioritize inclusivity and accessibility in our
                              designs. Our team integrates universal design
                              principles to create spaces that are usable and
                              welcoming to people of all abilities.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive13"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              How do you manage budget constraints during the
                              construction process?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive13" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Our team conducts a thorough cost analysis during
                              the planning phase to establish a realistic
                              budget. Regular cost monitoring and value
                              engineering help us stay within budget while
                              maintaining quality.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel sx-panel">
                        <div className="acod-head">
                          <h4 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFive14"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              What safety measures do you implement on
                              construction sites?
                              <span className="indicator">
                                <i className="fa fa-plus" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive14" className="acod-body collapse">
                          <div className="acod-content p-a15">
                            <p>
                              Safety is a top priority. We enforce strict safety
                              protocols, provide ongoing training to our team,
                              and adhere to industry standards to create a
                              secure working environment on construction sites.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

export default Faq;
