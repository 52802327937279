import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import { format } from "date-fns";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";
import BlockContent from "@sanity/block-content-to-react";
import Header from "../Common/Header.jsx";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/blog.jpg");
var bgimg1 = require("./../../images/background/cross-line2.png");

const BlogSingle = () => {
  const [blogData, setBlogData] = useState(null);
  const { slug } = useParams();
  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          publishedAt,      
          mainImage{
            asset->{
            _id,
            url
          }
        },
         imagesGallery[]{
          asset->{
            _id,
            url
           }
         },   
       body,
      
     }`,
        { slug }
      )
      .then((data) => setBlogData(data[0]))
      .catch(console.error);
  });

  return (
    <>
      <Header />
      <Helmet>
        <title>
          {blogData ? blogData.title : ""} | arK designspace - Insights on
          Architecture & Design
        </title>
        <link
          rel="canonical"
          href={`https://arkdesignspace.com/blog-detail/${
            blogData ? blogData.slug.current : ""
          }`}
        />

        <meta
          name="description"
          content={`Read ${
            blogData ? blogData.title : ""
          } on arK designspace's blog. Explore expert insights, creative trends, and innovative ideas in architecture, design, 3D modeling, and rendering.`}
        />
        <meta
          property="og:title"
          content={`${
            blogData ? blogData.title : ""
          } | arK designspace - Insights on
          Architecture & Design`}
        />

        <meta
          property="og:description"
          content={`Read ${
            blogData ? blogData.title : ""
          } on arK designspace's blog. Explore expert insights, creative trends, and innovative ideas in architecture, design, 3D modeling, and rendering.`}
        />

        <meta
          name="keywords"
          content={`${
            blogData ? blogData.title : ""
          }, arK designspace blog, architecture insights, design trends, 3D modeling tips, rendering techniques, creative design ideas`}
        />

        <meta
          property="og:image"
          content="https://arkdesignspace.com/ark.jpg"
        />
        <meta property="og:image:type" content="image/png" />
      </Helmet>
      <div className="page-content ">
        <Banner
          title="Blog Details"
          pagename="Blog"
          description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized."
          bgimage={bnrimg}
        />
        {/* SECTION CONTENT START */}
        <div className="section-full p-t80 p-b50 inner-page-padding">
          <div className="container">
            {!blogData ? (
              <>
                <h5>Loading...</h5>
              </>
            ) : (
              <>
                <div className="blog-single-space max-w900 ml-auto mr-auto">
                  {/* BLOG START */}
                  <div className="blog-post blog-detail text-black">
                    <div className="sx-post-media">
                      <div className="portfolio-item">
                        <img
                          className="img-responsive"
                          src={blogData.mainImage.asset.url}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="sx-post-meta  m-t20">
                      <ul>
                        <li className="post-date">
                          <strong>
                            {" "}
                            {format(
                              new Date(blogData.publishedAt),
                              "yyyy MMMM dd"
                            )}
                          </strong>
                        </li>
                        <li className="post-author">
                          <NavLink to={"#"}>
                            By <span>Admin</span>
                          </NavLink>{" "}
                        </li>
                        <li className="post-category">
                          <NavLink to={"#"}>
                            <span>ark designspace</span>
                          </NavLink>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="sx-post-title ">
                      <h2 className="post-titl">{blogData.title}</h2>
                    </div>
                    <div className="sx-post-text">
                      <BlockContent
                        blocks={blogData.body}
                        projectId="ajavin5j"
                        dataset="production"
                      />
                    </div>

                    {!blogData.imagesGallery ? (
                      <></>
                    ) : (
                      <>
                        <div className="sx-post-text">
                          <div className="row">
                            {blogData.imagesGallery.map((imgGal, indexGal) => (
                              <div className="col-md-6" key={indexGal}>
                                <div className="sx-box m-b30">
                                  <div className="sx-media">
                                    <img src={imgGal.asset.url} alt="" />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="autor-post-tag-share p-a30 bg-gray">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="widget_tag_cloud m-b15">
                            <h5 className="tagcloud">Tags</h5>
                            <div className="tagcloud">
                              <NavLink to="javascript:void(0)">
                                arK designspace
                              </NavLink>
                              <NavLink to="javascript:void(0)">
                                Exterior Design{" "}
                              </NavLink>
                              <NavLink to="javascript:void(0)">
                                Interior Design
                              </NavLink>
                              <NavLink to="javascript:void(0)">
                                Landscape{" "}
                              </NavLink>
                              <NavLink to="javascript:void(0)">
                                Consultant{" "}
                              </NavLink>
                              <NavLink to="javascript:void(0)">
                                Turnkey{" "}
                              </NavLink>
                              <NavLink to="javascript:void(0)">
                                Projects{" "}
                              </NavLink>
                              <NavLink to="javascript:void(0)">
                                Interior Make overs
                              </NavLink>
                              <NavLink to="javascript:void(0)">
                                2D Design Planning and Structurals
                              </NavLink>
                              <NavLink to="javascript:void(0)">
                                3D Exterior and Interior Designs{" "}
                              </NavLink>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="clearfix single-post-share">
                            <h5>Share this Post:</h5>
                            <div className="widget_social_inks">
                              <ul className="social-icons social-md social-square social-dark m-b0">
                                <li>
                                  <FacebookShareButton
                                    url={`https://arkdesignspace.com/blog-detail/${blogData.slug.current}`}
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      target="_blank"
                                      className="fa fa-facebook"
                                    />
                                  </FacebookShareButton>
                                </li>
                                <li>
                                  <TwitterShareButton
                                    url={`https://arkdesignspace.com/blog-detail/${blogData.slug.current}`}
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      target="_blank"
                                      className="fa fa-twitter"
                                    />
                                  </TwitterShareButton>
                                </li>
                                <li>
                                  <WhatsappShareButton
                                    url={`https://arkdesignspace.com/blog-detail/${blogData.slug.current}`}
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      target="_blank"
                                      className="fa fa-whatsapp"
                                    />
                                  </WhatsappShareButton>
                                </li>
                                <li>
                                  <TelegramShareButton
                                    url={`https://arkdesignspace.com/blog-detail/${blogData.slug.current}`}
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      target="_blank"
                                      className="fa fa-telegram"
                                    />
                                  </TelegramShareButton>
                                </li>
                                <li>
                                  <EmailShareButton
                                    url={`https://arkdesignspace.com/blog-detail/${blogData.slug.current}`}
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      target="_blank"
                                      className="fa fa-envelope-o"
                                    />
                                  </EmailShareButton>
                                </li>
                                <li>
                                  <LinkedinShareButton
                                    url={`https://arkdesignspace.com/blog-detail/${blogData.slug.current}`}
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      target="_blank"
                                      className="fa fa-linkedin"
                                    />
                                  </LinkedinShareButton>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* SECTION CONTENT END */}
      </div>

      <Footer />
    </>
  );
};

export default BlogSingle;
