import React from "react";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import Header from "../Common/Header";
import Plans1 from "../Elements/Plans1";
import ContactUs1 from "../Elements/ContactUs1";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/price.jpg");

const PricingTable = () => {
  return (
    <>
      <Header />
      <Helmet>
        <title>
          Pricing | arK designspace - Transparent Rates for Architecture &
          Design Services
        </title>
        <link rel="canonical" href="https://arkdesignspace.com/pricing-table" />

        <meta
          name="description"
          content="Discover clear and competitive pricing for arK designspace's architectural and design services. Explore packages for 3D modeling, rendering, interior design, and more to suit your needs and budget."
        />

        <meta
          property="og:title"
          content="Pricing | arK designspace - Transparent Rates for Architecture & Design Services"
        />
        <meta
          property="og:description"
          content="Discover clear and competitive pricing for arK designspace's architectural and design services. Explore packages for 3D modeling, rendering, interior design, and more to suit your needs and budget."
        />

        <meta
          name="keywords"
          content="arK designspace pricing, architecture pricing table, design service rates, 3D modeling cost, rendering services pricing, interior design packages, affordable design services, architecture pricing plans"
        />

        <meta
          property="og:image"
          content="https://arkdesignspace.com/ark.jpg"
        />
        <meta property="og:image:type" content="image/png" />
      </Helmet>
      <div className="page-content">
        <Banner
          title="Pricing Table"
          pagename="pricing-table"
          description="The prices are just placeholders and should be customized based on your specific services, expertise, and market conditions."
          bgimage={bnrimg}
        />
        {/* SECTION CONTENTG START */}

        <Plans1 />
        <ContactUs1 />

        {/* SECTION CONTENT END */}
      </div>

      <Footer />
    </>
  );
};

export default PricingTable;
